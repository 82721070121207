import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Button } from '@Components/Button';
import classes from './AllRegisteredData.module.scss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material//Hidden';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import texaActivated from './../../../../assets/Texa_Activated.svg';
import accordionMoreIcon from './../../../../assets/dashboardICons/accordionMore.svg';
import accordionLessIcon from './../../../../assets/dashboardICons/accordionLess.svg';
import accordionMoreColorIcon from './../../../../assets/dashboardICons/accordionMoreColorIcon.svg';
import Typography from '@mui/material/Typography';
import { RegisterEq } from './../../RegisterEq';
import { getUserSFDetails } from 'utils/getUserAccount';
import { instalationQuery } from './../../../Installation/Installation.query';
import Modal from '@mui/material/Modal';
import texaIcon from './../../../../assets/dashboardICons/texaIcon.svg';
import touchIcon from './../../../../assets/touch_screen.png';
import closeIcon from './../../../../assets/closeIcon.svg';
import { Link } from 'react-router-dom';
import { RequestForTransfer } from 'features/dahboard/RotaryAccordion/RequestForTransfer';

const AllRegisteredData = ({ equipment: uneq }) => {
  const [openRegister, setOpenRegister] = React.useState(false);
  const [isShown, setIsShown] = React.useState(false);
  const [openAccordionId, setOpenAccordionId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleAccordionChange = (itemId) => {
    setOpenAccordionId(itemId === openAccordionId ? null : itemId);
  };
  const [activatedPopup, setactivatedPopup] = React.useState(false);
  const [activatePopup, setActivatePopup] = React.useState(false);

  const activeProduct = () => {
    setActivatePopup(true);
  };
  const handleClose = () => {
    setActivatePopup(false);
  };
  const handleOpen = () => setOpen(true);
  const userData = getUserSFDetails();
  const {
    mutate,
    status,
    data: texaDetails,
  } = instalationQuery.useMutationtexaActivate();
  const handleTexaActivate = () => {
    const payload = {
      userId: userData.id,
      userEmail: userData.email,
      equipmentId: uneq?.equipmentId,
      equipmentNumber: uneq?.equipmentNumber,
      firstName: userData.firstName,
      lastName: userData.lastName,
    };
    mutate(payload);
    setactivatedPopup(true);
  };
  const handleClosed = () => {
    // dashboardQuery.useProducts();
    // setTimeout(() => navigate('/dashboard'), 300);
    setactivatedPopup(false);
    setActivatePopup(false);
    // window.location.reload();
  };
  const activedCode = localStorage.getItem('activatedCode');
  return (
    <>
      <Hidden mdDown>
        <div className={classes.unregisterDataCont}>
          <div className={classes.rotaryHeading}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} className={classes.rotaryHeadingText}>
                <Grid
                  item
                  xs={6}
                  md={1.5}
                  className={classes.rotaryHeadingCont}
                >
                  <p className={classes.hoverText}>
                    <h2 className={classes.rotaryTitle}>{uneq?.productName}</h2>
                    <span className={classes.tooltip}>{uneq?.productName}</span>
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={1.5}
                  className={classes.rotaryHeadingCont}
                >
                  <p className={classes.registrationDatesub}>
                    {uneq?.productCode}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={1.5}
                  className={classes.rotaryHeadingCont}
                >
                  <p className={classes.serialNumSub}>
                    {uneq?.equipmentNumber}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={1.5}
                  className={classes.rotaryHeadingCont}
                >
                  <p className={classes.hoverText}>
                    <h2 className={classes.rotaryTitle}>
                      {uneq?.distributorName}
                    </h2>
                    <span className={classes.tooltip}>
                      {uneq?.distributorName}
                    </span>
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={1.5}
                  className={classes.rotaryHeadingCont}
                >
                  {/* <p className={classes.registrationDatesub}>
                    {uneq?.installerName}
                  </p> */}
                  <p className={classes.hoverText}>
                    <h2 className={classes.rotaryTitle}>
                      {uneq?.installerName}
                    </h2>
                    <span className={classes.tooltip}>
                      {uneq?.installerName}
                    </span>
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={1.5}
                  className={classes.rotaryHeadingCont}
                >
                  {/* <p className={classes.registrationDatesub}>
                    {uneq?.installerType}
                  </p> */}
                  <p className={classes.hoverText}>
                    <h2 className={classes.rotaryTitle}>
                      {uneq?.installerType}
                    </h2>
                    <span className={classes.tooltip}>
                      {uneq?.installerType}
                    </span>
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={1.5}
                  className={classes.rotaryHeadingCont}
                >
                  <p className={classes.hoverText}>
                    <h2 className={classes.rotaryTitle}>
                      {uneq?.installedLocation}
                    </h2>
                    <span className={classes.tooltip}>
                      {uneq?.installedLocation}
                    </span>
                  </p>
                  {/* <p className={classes.registrationDatesub}>
                    {uneq?.installedLocation}
                  </p> */}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={1.5}
                  className={classes.rotaryHeadingCont}
                >
                  <p className={classes.registrationDatesub}>
                    <div className={classes.transfer}>
                      <Button type="submit" onClick={handleOpen}>
                        Transfer
                      </Button>
                    </div>
                  </p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.rotaryCardContainerMobile}>
          <Accordion
            className={classes.headingSection}
            expanded={openAccordionId === uneq.equipmentNumber}
            onChange={() => handleAccordionChange(uneq.equipmentNumber)}
          >
            <div
              className={
                openAccordionId === uneq.equipmentNumber
                  ? classes.headingDetailsFocus
                  : classes.headingDetails
              }
            >
              <AccordionSummary
                expandIcon={
                  openAccordionId === uneq.equipmentNumber ? (
                    <img src={accordionMoreColorIcon} alt="accordionLessIcon" />
                  ) : isShown ? (
                    <img src={accordionMoreColorIcon} alt="accordionLessIcon" />
                  ) : !isShown ? (
                    <img
                      src={accordionMoreIcon}
                      alt="accordionMoreIcon"
                      className={classes.iconStyles}
                    />
                  ) : (
                    <img src={accordionLessIcon} alt="accordionLessIcon" />
                  )
                }
                // onMouseEnter={() => setIsShown(true)}
                // onMouseLeave={() => setIsShown(false)}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionExpand}
              >
                <div className={classes.rotaryHeading}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.rotaryHeadingText}
                    >
                      {' '}
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.rotaryHeadingCont}
                      >
                        <p className={classes.subHead}>Product Name</p>
                        <h2 className={classes.rotaryHeading}>
                          {uneq?.productName}
                        </h2>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </AccordionSummary>
            </div>
            <AccordionDetails style={{ border: '1.5px solid #CF8686' }}>
              <Typography>
                <div className={classes.cardDetailSection}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.rotaryHeadingText}
                    >
                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Product Code</p>
                        <p className={classes.subDetails}>
                          {uneq?.productCode}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={4}
                        className={classes.rotaryHeadingCont}
                      >
                        <p className={classes.subHead}>Serial Number</p>
                        <p className={classes.subDetails}>
                          {uneq?.equipmentNumber}
                        </p>
                      </Grid>

                      <Grid item xs={6} md={4}>
                        <p className={classes.subHead}>Distributor</p>
                        <p className={classes.subDetails}>
                          {uneq?.distributorName}
                        </p>
                      </Grid>
                      <Grid item xs={6} md={1.5} sm={6}>
                        <p className={classes.subHead}>Installer</p>
                        <p className={classes.hoverText}>
                          <p className={classes.registrationDatesub}>
                            {uneq?.installerName}
                          </p>
                        </p>
                      </Grid>
                      <Grid item xs={6} md={1.5} sm={6}>
                        <p className={classes.subHead}>InstallerType</p>
                        <p className={classes.registrationDatesub}>
                          {uneq?.installerType}
                        </p>
                      </Grid>
                      <Grid item xs={6} md={1.5} sm={6}>
                        <p className={classes.subHead}>Location</p>
                        <p className={classes.registrationDatesub}>
                          {uneq?.installedLocation}
                        </p>
                      </Grid>
                      <div className={classes.transfer}>
                        <Button type="submit" onClick={handleOpen}>
                          Transfer
                        </Button>
                      </div>
                    </Grid>
                  </Box>
                  {uneq.isTexaProduct === true &&
                    uneq.isTexaProductActivated === false &&
                    texaDetails?.data?.response !== 1 && (
                      <div className={classes.popupActiveButtons}>
                        <Button
                          type="submit"
                          onClick={activeProduct}
                          className={classes.popupActiveButton}
                        >
                          Activate Now
                        </Button>
                      </div>
                    )}

                  {uneq.isTexaProduct === true &&
                    uneq.isTexaProductActivated === true && (
                      <div className={classes.activeTextMobile}>
                        {' '}
                        <span>
                          <img
                            src={texaActivated}
                            alt="Texa Product Activated"
                          />
                        </span>
                        Equipment Activated
                      </div>
                    )}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Hidden>
      <div>
        <Modal
          open={activatePopup === true && texaDetails?.data?.response !== 1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.ModalStyle}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={classes.modalTitle}
            >
              Equipment is Inactive
            </Typography>

            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={classes.paraGraph}
              // style={{ textAlign: 'center' }}
            >
              <div className={classes.texaInactiveText}>
                <div>
                  <img src={texaIcon} alt="TexaIcon" />
                </div>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  Your equipment is registered but not activated. Please
                  activate it.
                </div>
              </div>
            </Typography>

            <div className={classes.popupActiveButtons}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                className={classes.cancelButton}
              >
                Activate Later
              </Button>
              <Button
                type="submit"
                className={classes.popupActiveButton}
                onClick={() => {
                  handleTexaActivate();
                }}
              >
                Activate Now
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={activatedPopup === true && texaDetails?.data?.response !== 1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.ModalStyle}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={classes.modalTitle}
            >
              Activation Code
              <Link onClick={handleClosed} className={classes.closeIcon}>
                <img src={closeIcon} alt="closeIcon" />
              </Link>
            </Typography>

            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={classes.paraGraphcont}
              // style={{ textAlign: 'center' }}
            >
              <div className={classes.texaInactiveText}>
                <div>
                  <img src={touchIcon} alt="Touch Icon" />
                </div>
                <div className={classes.texaActivatedText}>
                  <p>
                    Your Equipment has been activated from our end, Please enter
                    the activation code below during equipment set up
                  </p>
                </div>
                {/* <div className={classes.texaActivatedCode}>{activedCode}</div> */}
                <span className={classes.texaActivatedCodeSpan}>
                  An email has also been sent with the activation code to your
                  registered email
                </span>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>

      <div className={classes.RegisterEquipt_Modal}>
        {openRegister && (
          <RegisterEq open={openRegister} setOpen={setOpenRegister} />
        )}
      </div>
      <div className={classes.RegisterEquipt_Modal}>
        {open && (
          <RequestForTransfer open={open} setOpen={setOpen} uneq={uneq} />
        )}
      </div>
    </>
  );
};
export default AllRegisteredData;
