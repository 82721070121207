/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { Hidden } from '@mui/material';
import { Button } from '@Components/Button';
import { spinnerStore } from '@Store/spinnerStore';
import classes from './dashboard.module.scss';
import { RegisterEq } from './RegisterEq';
import { TablePaginationGrid } from './CardsGrid';
import { dashboardQuery } from './dashboard.query';
import scrollToTop from 'utils/scrollToTop';
import getUserAccount, {
  getUserSFDetails,
  setUserSFDetails,
} from 'utils/getUserAccount';
import { authService } from '@Services/auth';
import { DistributorDashboard } from './DistributorDashboard';
import NoResultsFound from '@Components/NoRecordsFound/NoResultsFound';
import { CardAccordion } from './RotaryAccordion';
import SearchIcon from './../../assets/dashboardICons/Search_light.svg';
import xIcon from './../../assets/dashboardICons/xIcon.svg';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import UnregisteredTab from './UnregisteredTab/UnregisteredTab';
import AllRegisteredTab from './AllRegisteredTab/AllRegisteredTab';
import AllUnregisteredTab from './AllUnregisteredTab/AllUnregisteredTab';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function Dashboard() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [showDistrbutorModal, setShowDistrbutorModal] = useState(false);
  const handleOpen = () => {
    const userSf = getUserSFDetails();
    if (userSf.userRole === 'Distributor' && !userSf.distributorNumber) {
      setShowDistrbutorModal(true);
    } else if (userSf.userRole === 'Installer' && !userSf.installerNumber) {
      setShowDistrbutorModal(true);
    } else {
      setOpen(true);
    }
  };
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { setSpinner, isActive } = spinnerStore();
  const [searchText, setSearchText] = useState('');
  const [searchTextUn, setSearchTextUn] = useState('');
  const [iconFlag, setIconFlag] = useState(false);
  const [pageValue, setPageValue] = useState(1);
  const [payload, setPayload] = useState('');
  const [searchPayload, setSearchPayload] = useState('');
  const [checkedCount, setCheckedCount] = useState(false);
  const [multiProductPayload, setMultiProductPayload] = useState('');
  const [fromunregisteredData, setFromUnRegisteredData] = useState([]);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const { data, status, isSuccess, isRefetching, isLoading } =
    dashboardQuery.useProducts(page + 1, rowsPerPage, payload, pageValue);

  // const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading || isRefetching) {
      setSpinner(isLoading || isRefetching);
    }
  }, [isLoading, isRefetching]);
  // useEffect(() => {
  //   setSpinner(isRefetching);
  // }, [isRefetching]);
  // const {
  //   data: unRegData,
  //   status: unRegStatus,
  //   isSuccess: unRegDataisSuccess,
  // } = dashboardQuery.useUnregisteredProducts(
  //   page + 1,
  //   rowsPerPage,
  //   searchPayload,
  //   pageValue,
  // );

  // For unregistered data api call

  //register multiserial numbers api
  const {
    mutate,
    status: multiProductStatus,
    data: multiProductData,
    isFetching: multiIsFetching,
  } = dashboardQuery.useMultiMutationProducts();
  // useEffect(() => {
  //   setSpinner(multiIsFetching);
  // }, [multiIsFetching]);
  const user = getUserSFDetails();
  const fetchResults = (event) => {
    const encodedSearchQuery = encodeURIComponent(searchText);
    event.preventDefault();
    setPayload(encodedSearchQuery);
    setIconFlag(true);
  };
  //passing search value to child component
  const AllUnRef = useRef();
  const AllRegRef = useRef();
  const fetchResultsALLREG = (event) => {
    const encodedSearchQuery = encodeURIComponent(searchTextUn);
    const popupRef = AllRegRef.current;
    if (popupRef) {
      popupRef.showView(encodedSearchQuery);
    }
    event.preventDefault();
    setSearchPayload(encodedSearchQuery);
    setIconFlag(true);
  };
  const handleClickAllRegister = () => {
    const popupRef = AllRegRef.current;
    if (popupRef) {
      popupRef.showView('');
    }
    setSearchTextUn('');
    setSearchPayload('');
    setIconFlag(false);
    setPageValue(1);
  };
  const fetchResultsUR = (event) => {
    const encodedSearchQuery = encodeURIComponent(searchTextUn);
    const popupRef = AllUnRef.current;
    if (popupRef) {
      popupRef.showView(encodedSearchQuery);
    }
    event.preventDefault();
    setSearchPayload(encodedSearchQuery);
    setIconFlag(true);
  };

  const handleClickUnRegister = () => {
    const popupRef = AllUnRef.current;
    if (popupRef) {
      popupRef.showView('');
    }
    setSearchTextUn('');
    setSearchPayload('');
    setIconFlag(false);
    setPageValue(1);
  };
  const handleClickUnRegisterEquip = () => {
    localStorage.removeItem('statusId');
    let dataToRegister =
      user.userRole === 'CSR'
        ? dataFromAllUnregisteredTAbValues()
        : fromunregisteredData;

    setSpinner(true);
    setMultiProductPayload(dataToRegister.toString());
    setTimeout(() => {
      mutate(dataToRegister.toString());
    }, 100);
  };

  const [currentPageNum, setCurrentPageNum] = useState(1);
  //coping data from unregisteredTAB
  const dataFromAllUnregisteredTAb = (unregData, currentpage, allData) => {
    setCurrentPageNum(currentpage);
    setFromUnRegisteredData(unregData);
    const currentPageData = unregData.filter((item) => {
      return item.page === currentpage;
    });
    const pageData =
      currentPageData.length > 0
        ? currentPageData[0]
        : { page: currentpage, data: [] };
    if ((pageData.data.length ?? 0) === allData?.length) {
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
    }
  };
  const dataFromAllUnregisteredTAbValues = () => {
    let allUnregisteredCheckedVal = [];
    fromunregisteredData.forEach((item) => {
      const checkedDATA = item.data;
      if (checkedDATA) {
        allUnregisteredCheckedVal = [
          ...allUnregisteredCheckedVal,
          ...checkedDATA,
        ];
      }
    });
    return allUnregisteredCheckedVal;
  };
  const dataToRegisterCount =
    user.userRole === 'CSR'
      ? dataFromAllUnregisteredTAbValues()
      : fromunregisteredData;

  useEffect(() => {}, [searchPayload]);
  useEffect(() => {
    dataToRegisterCount.length > 0
      ? setCheckedCount(true)
      : setCheckedCount(false);
  }, [dataToRegisterCount]);
  const dataFromUnregisteredTAb = (unregData) => {
    setFromUnRegisteredData(unregData);
  };

  const onCountChange = (dataCount) => {
    setTotalDataCount(dataCount);
    console.warn(dataCount, 'datacount987');
  };
  // const selectAllCheckRef = useRef();
  const handleChangeForSelectAll = (e) => {
    const allCheck = e.target.checked;
    //setOpen(true);
    const selectAll = AllUnRef.current;
    if (selectAll) {
      const selectedVal = selectAll.selectAll(allCheck) ?? false;
      setSelectAllCheck(selectedVal);
    }
  };
  const texaSerialnum = [];
  const selectedSerialNum = [];
  useEffect(() => {
    if (multiProductData && multiProductStatus === 'success') {
      localStorage.removeItem('CheckedValue');
      console.warn(multiProductData, 'multiProductData');
      if (multiProductData.length > 1) {
        multiProductData.forEach((item) => {
          if (item.isTexaProduct) {
            texaSerialnum.push(item.equipmentNumber);
            console.warn(texaSerialnum, 'texaSerialnum');
          } else {
            selectedSerialNum.push(item.equipmentNumber);
            console.warn(selectedSerialNum, 'selectedSerialNum');
          }
        });
        if (texaSerialnum.length >= 1) {
          toast.error(
            'Texa equipment ' +
              texaSerialnum +
              ' must be registered separately and cannot be included in multiple registrations with other equipment.',
            {
              position: toast.POSITION.TOP_CENTER,
            },
          );
          setSpinner(false);
        }
        if (selectedSerialNum.length > 0) {
          console.warn(selectedSerialNum, 'selectedSerialNum');
          localStorage.setItem('CheckedValues', selectedSerialNum);
          setMultiProductPayload(selectedSerialNum);
          setTimeout(() => {
            //TODO - this is hard coded
            setSpinner(false);
            navigate('/installation/' + selectedSerialNum);
          }, 1000);
        }
      } else {
        localStorage.setItem('CheckedValues', multiProductPayload);
        setTimeout(() => {
          //TODO - this is hard coded
          setSpinner(false);
          navigate('/installation/' + multiProductPayload);
        }, 1000);
      }
    }

    localStorage.setItem('checkedValuesLength', multiProductPayload.length);
  }, [multiProductData, multiProductStatus]);
  const handleChangePage = (event, newPage) => {
    if (payload !== '') {
      if (pageValue < data.totalPages) {
        setPageValue(pageValue + 1);
      }
    }

    if (payload !== '') {
      if (newPage < data?.pageNumber - 1) {
        // if (pageValue < data?.pageNumber - 1) {
        setPageValue(pageValue - 1);
      }
    }
    setPage(newPage);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    scrollToTop();
  };
  const handleClick = () => {
    setSearchText('');
    setPayload('');
    setIconFlag(false);
    setPageValue(1);
  };

  const onSucessPopUp = () => {
    setShowDistrbutorModal(false);
    const userAccount = getUserAccount();
    authService.getUserDetails(userAccount.email).then(({ data }) => {
      setUserSFDetails(data.response);
    });
  };

  const handlePopup = (value) => {
    setShowDistrbutorModal(value);
  };
  useEffect(() => {
    if (location.search !== '') {
      setOpen(true);
    }
  }, [!location.search !== '']);
  useEffect(() => {
    if (!searchText) {
      setIconFlag(false);
      return setPayload('');
    }
  }, [searchText]);
  useEffect(() => {
    if (!searchTextUn) {
      setIconFlag(false);
      return setSearchPayload('');
    }
  }, [searchTextUn]);

  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     event.preventDefault();
  //     event.returnValue = '';
  //     return '';
  //     localStorage.clear();
  //     sessionStorage.clear();
  //   };
  //   window.onbeforeunload = null;
  //   window.addEventListener('beforeunload', unloadCallback);
  //   return () =>
  //     window.removeEventListener('dom.disable_beforeunload', unloadCallback);
  // }, []);
  return (
    <>
      <div className={classes.dashboardNavbarSection}>
        <h2>My Equipment</h2>
        {(value === 0 || user.userRole === 'Basic User') && (
          <>
            <Hidden mdDown>
              <div className={classes.searchForm}>
                <form class={classes.nosubmit} onSubmit={fetchResults}>
                  <div className={classes.searchContainer}>
                    <input
                      className={classes.searchInput}
                      type="text"
                      placeholder="Search by Serial Number, Product Name or Code"
                      onChange={(e) => setSearchText(e.target.value)}
                      value={searchText}
                    />
                    {(iconFlag === false || searchText === '') && (
                      <img
                        src={SearchIcon}
                        alt="SearchIcon"
                        className={classes.searchIcon}
                        onClick={fetchResults}
                      />
                    )}
                    {iconFlag === true && searchText !== '' && (
                      <img
                        src={xIcon}
                        alt="xIcon"
                        className={classes.searchIcon}
                        onClick={handleClick}
                      />
                    )}
                  </div>
                </form>
              </div>
            </Hidden>
            <div className={classes.registerNew} value={value} index={0}>
              <Hidden mdUp>
                {/* {user.userRole === 'CSR' ? (
                  <Button type="submit" onClick={handleOpen}>
                    Register or Transfer
                  </Button>
                ) : ( */}
                <Button type="submit" onClick={handleOpen}>
                  Register
                </Button>
              </Hidden>
              <Hidden mdDown>
                <Button type="submit" onClick={handleOpen}>
                  Register New Equipment
                </Button>
              </Hidden>
            </div>
          </>
        )}
        {value === 1 && (
          <>
            <Hidden mdDown>
              <div className={classes.searchForm}>
                <form class={classes.nosubmit} onSubmit={fetchResultsALLREG}>
                  <div className={classes.searchContainer}>
                    <input
                      className={classes.searchInput}
                      type="text"
                      placeholder="Search by Serial Number, Product Name or Code"
                      onChange={(e) => setSearchTextUn(e.target.value)}
                      value={searchTextUn}
                    />
                    {(iconFlag === false || searchTextUn === '') && (
                      <img
                        src={SearchIcon}
                        alt="SearchIcon"
                        className={classes.searchIcon}
                        onClick={fetchResultsALLREG}
                      />
                    )}
                    {iconFlag === true && searchTextUn !== '' && (
                      <img
                        src={xIcon}
                        alt="xIcon"
                        className={classes.searchIcon}
                        onClick={handleClickAllRegister}
                      />
                    )}
                  </div>
                </form>
              </div>
            </Hidden>
            <div className={classes.registerNew}>
              <Hidden mdUp>
                {/* {user.userRole === 'CSR' ? (
                  <Button type="submit" onClick={handleOpen}>
                    Register or Transfer
                  </Button>
                ) : checkedCount ? ( */}
                {checkedCount ? (
                  <Button type="submit" onClick={handleClickUnRegisterEquip}>
                    Register
                  </Button>
                ) : (
                  <Button type="submit" onClick={handleOpen}>
                    Register
                  </Button>
                )}
              </Hidden>
              <Hidden mdDown>
                {checkedCount ? (
                  <Button type="submit" onClick={handleClickUnRegisterEquip}>
                    Register Selected equipment
                  </Button>
                ) : (
                  <Button type="submit" onClick={handleOpen}>
                    Register New Equipment
                  </Button>
                )}
              </Hidden>
            </div>
          </>
        )}
        {value === 2 && (
          <>
            <Hidden mdDown>
              <div className={classes.searchForm}>
                <form class={classes.nosubmit} onSubmit={fetchResultsUR}>
                  <div className={classes.searchContainer}>
                    <input
                      className={classes.searchInput}
                      type="text"
                      placeholder="Search by Serial Number, Product Name or Code"
                      onChange={(e) => setSearchTextUn(e.target.value)}
                      value={searchTextUn}
                    />
                    {(iconFlag === false || searchTextUn === '') && (
                      <img
                        src={SearchIcon}
                        alt="SearchIcon"
                        className={classes.searchIcon}
                        onClick={fetchResultsUR}
                      />
                    )}
                    {iconFlag === true && searchTextUn !== '' && (
                      <img
                        src={xIcon}
                        alt="xIcon"
                        className={classes.searchIcon}
                        onClick={handleClickUnRegister}
                      />
                    )}
                  </div>
                </form>
              </div>
            </Hidden>
            <div className={classes.registerNew}>
              <Hidden mdUp>
                {checkedCount ? (
                  <Button type="submit" onClick={handleClickUnRegisterEquip}>
                    Register
                  </Button>
                ) : (
                  <Button type="submit" onClick={handleOpen}>
                    Register
                  </Button>
                )}
              </Hidden>
              <Hidden mdDown>
                {checkedCount ? (
                  <Button type="submit" onClick={handleClickUnRegisterEquip}>
                    Register Selected equipment
                  </Button>
                ) : (
                  <Button type="submit" onClick={handleOpen}>
                    Register New Equipment
                  </Button>
                )}
              </Hidden>
            </div>
          </>
        )}
        <Hidden mdUp>
          <div className={classes.searchForm}>
            <form class={classes.nosubmit} onSubmit={fetchResults}>
              <div className={classes.searchContainer}>
                <input
                  className={classes.searchInput}
                  type="text"
                  placeholder="Search by Serial Number, Product Name or Code"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
                {(iconFlag === false || searchText === '') && (
                  <img
                    src={SearchIcon}
                    alt="SearchIcon"
                    className={classes.searchIcon}
                    onClick={fetchResults}
                  />
                )}
                {iconFlag === true && searchText !== '' && (
                  <img
                    src={xIcon}
                    alt="xIcon"
                    className={classes.searchIcon}
                    onClick={handleClick}
                  />
                )}
              </div>
            </form>
          </div>
        </Hidden>
      </div>
      {user.userRole === 'CSR' ? (
        <Box sx={{ width: '100%' }}>
          <div className={classes.navTabs}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                overflowX: 'auto',
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: { backgroundColor: '#CE1F1F', marginBottom: '2px' },
                }}
                aria-label="basic tabs example"
                sx={{
                  '& button.Mui-selected': { color: '#CE1F1F' },
                  '& button': { textTransform: 'unset' },
                }}
                className={classes.tabs}
              >
                <Tab label="My Registered" {...a11yProps(0)} />
                <Tab label="All Registered" {...a11yProps(1)} />
                <Tab label="All Unregistered" {...a11yProps(2)} />
              </Tabs>
            </Box>
          </div>
          <TabPanel value={value} index={0} className={classes.tabpanel}>
            <>
              {isSuccess && data && (
                <>
                  <div className={classes.middleContainer}>
                    {data?.equipments.map((eq) => (
                      <>
                        <CardAccordion
                          equipment={eq}
                          key={eq?.equipmentNumber}
                        />
                      </>
                    ))}
                  </div>
                  {data?.totalRecords > 2 && (
                    <div className={classes.pagination}>
                      <TablePaginationGrid
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        page={data?.pageNumber - 1}
                        rowsPerPage={data?.pageSize}
                        count={data?.totalRecords}
                      />
                    </div>
                  )}
                </>
              )}
              {isSuccess && !data && (
                <NoResultsFound handleClick={handleOpen} />
              )}
            </>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className={classes.middleContainerwithAllData}
          >
            <Hidden mdDown>
              <div>
                <div className={classes.productHeader}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        md={1.5}
                        className={classes.rotaryHeadSec}
                      >
                        <p className={classes.subHead}>Product Name</p>
                      </Grid>
                      <Grid item xs={6} md={1.5} sm={3}>
                        <p className={classes.subHead}>Product Code</p>
                      </Grid>
                      <Grid item xs={6} md={1.5} sm={3}>
                        <p className={classes.subHead}>Serial Number</p>
                      </Grid>
                      <Grid item xs={12} md={1.5} sm={6}>
                        <p className={classes.subHead}>Distributor</p>
                      </Grid>
                      <Grid item xs={12} md={1.5} sm={6}>
                        <p className={classes.subHead}>Installer</p>
                      </Grid>
                      <Grid item xs={12} md={1.5} sm={6}>
                        <p className={classes.subHead}>InstallerType</p>
                      </Grid>
                      <Grid item xs={12} md={1.5} sm={6}>
                        <p className={classes.subHead}>Location</p>
                      </Grid>
                      <Grid item xs={12} md={1.5} sm={6}>
                        <p className={classes.subHead}>Action</p>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </div>
            </Hidden>
            <AllRegisteredTab
              selectedEquipmentdata={fromunregisteredData}
              onChange={dataFromUnregisteredTAb}
              ref={AllRegRef}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            className={classes.middleContainerwithAllUnRegData}
          >
            <Hidden mdDown>
              <div>
                <div className={classes.productHeader}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={1} sm={3}>
                        {' '}
                        <input
                          // value="test"
                          type="checkbox"
                          onChange={handleChangeForSelectAll}
                          className={classes.checkboxStyle}
                          // value={uneq?.equipmentNumber}
                          checked={selectAllCheck}
                        />
                      </Grid>
                      <Grid item xs={6} md={1.5} sm={3}>
                        <p className={classes.subHead}>Serial Number</p>
                      </Grid>
                      <Grid item xs={6} md={2} sm={3}>
                        <p className={classes.subHead}>Product Code</p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.rotaryHeadSec}
                      >
                        <p className={classes.subHead}>Product Name</p>
                      </Grid>
                      <Grid item xs={12} md={4} sm={6}>
                        <p className={classes.subHead}>Distributor</p>
                      </Grid>
                      {/* <Grid item xs={12} md={1.5} sm={6}>
                        <p className={classes.subHead}>Action</p>
                      </Grid> */}
                    </Grid>
                  </Box>
                </div>
              </div>
            </Hidden>
            <AllUnregisteredTab
              selectedEquipmentdata={fromunregisteredData}
              onChange={dataFromAllUnregisteredTAb}
              ref={AllUnRef}
              onCountChange={onCountChange}
            />
          </TabPanel>
        </Box>
      ) : user.userRole === 'Basic User' ? (
        isSuccess &&
        data && (
          <>
            <div className={classes.middleContainer}>
              {data?.equipments.map((uneq) => (
                <>
                  <CardAccordion equipment={uneq} key={uneq?.equipmentNumber} />
                </>
              ))}
            </div>
            {data?.totalRecords > 2 && (
              <div className={classes.pagination}>
                <TablePaginationGrid
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={data?.pageNumber - 1}
                  rowsPerPage={data?.pageSize}
                  count={data?.totalRecords}
                />
              </div>
            )}
          </>
        )
      ) : (
        <Box sx={{ width: '100%' }}>
          <div className={classes.navTabs}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: { backgroundColor: '#CE1F1F' },
                }}
                aria-label="basic tabs example"
                sx={{
                  '& button.Mui-selected': { color: '#CE1F1F' },
                  '& button': { textTransform: 'unset' },
                }}
              >
                <Tab label="Registered" {...a11yProps(0)} />
                <Tab label="Unregistered" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </div>
          <TabPanel value={value} index={0}>
            <>
              {isSuccess && data && (
                <>
                  <div className={classes.middleContainer}>
                    {data?.equipments.map((uneq) => (
                      <>
                        <CardAccordion
                          equipment={uneq}
                          key={uneq?.equipmentNumber}
                        />
                      </>
                    ))}
                  </div>
                  {data?.totalRecords > 2 && (
                    <div className={classes.pagination}>
                      <TablePaginationGrid
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        page={data?.pageNumber - 1}
                        rowsPerPage={data?.pageSize}
                        count={data?.totalRecords}
                      />
                    </div>
                  )}
                </>
              )}
              {isSuccess && !data && (
                <NoResultsFound handleClick={handleOpen} />
              )}
            </>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* {unRegDataisSuccess && unRegData && (
              <>
                <div className={classes.middleContainer}>
                  {unRegData?.equipments.map((uneq) => (
                    <>
                      <UnregisterData
                        equipment={uneq}
                        key={uneq?.equipmentNumber}
                        selectedEquip={allchecked}
                        selectedEquipment={selectedEquipment}
                        ref={unRegSearch}
                      />
                    </>
                  ))}
                </div>
                {unRegData?.totalRecords > 2 && (
                  <div className={classes.pagination}>
                    <TablePaginationGrid
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      page={unRegData?.pageNumber - 1}
                      rowsPerPage={unRegData?.pageSize}
                      count={unRegData?.totalRecords}
                    />
                  </div>
                )}
              </>
            )}
            {unRegDataisSuccess && !unRegData && (
              <NoResultsFound handleClick={handleOpen} />
            )} */}
            <UnregisteredTab
              selectedEquipmentdata={fromunregisteredData}
              onChange={dataFromUnregisteredTAb}
              ref={AllUnRef}
            />
          </TabPanel>
        </Box>
      )}
      {user.userRole === 'Basic User' && isSuccess && !data && (
        <NoResultsFound handleClick={handleOpen} />
      )}
      <div className={classes.RegisterEquipt_Modal}>
        {open && <RegisterEq open={open} setOpen={setOpen} />}
      </div>
      {showDistrbutorModal && (
        <DistributorDashboard
          open={showDistrbutorModal}
          setOpen={handlePopup}
          onSucessPopUp={onSucessPopUp}
        />
      )}
    </>
  );
}

export default Dashboard;
